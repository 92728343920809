import { StepFormDataType } from "../../StepFormTypes";
import {
  getStepFormButton,
  getStepFormInputField,
} from "../formDataElementsUtils";

export const stepFormDataAcademy: StepFormDataType = {
  steps: [
    {
      id: "academy",
      title: "ai.academy.title",
      answer: {
        type: "input_fields",
        answerConfig: {
          submitForm: true,
          fields: [
            getStepFormInputField("ai.talent.contactInfo.email", "email"),
          ],
          nextButtonLabel: "ai.submit",
          nextStepId: "academy-2",
        },
      },
    },
    {
      id: "academy-2",
      title: "ai.academy.end",
      answer: {
        type: "info",
        answerConfig: {},
      },
    },
  ],
};
