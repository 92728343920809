import { aiAcademyTranslations } from "./aiAcademyTranslations";
import { aiCTATranslations } from "./aiCTATranslations";
import { aiDataFactsTranslations } from "./aiDataFactsTranslations";
import { aiHeroTranslations } from "./aiHeroTranslations";
import { aiIndustriesTranslations } from "./aiIndustriesTranslations";
import { aiQATranslations } from "./aiQaTranslations";
import { aiServicesTranslations } from "./aiServicesTranslations";
import { aiUseCasesTranslations } from "./aiUseCasesTranslations";

export const aiTranslations = {
  hero: aiHeroTranslations,
  dataFacts: aiDataFactsTranslations,
  services: aiServicesTranslations,
  experience: aiUseCasesTranslations,
  callToAction: aiCTATranslations,
  qa: aiQATranslations,
  industries: aiIndustriesTranslations,
  academy: aiAcademyTranslations,
  trustedPartner: {
    en: "Trusted Partner of 60+ Teams & Professionals Worldwide",
    es: "Socio de Confianza de más de 60 Equipos y Profesionales en todo el Mundo",
    de: "Vertrauenspartner von über 60 Teams & Fachleuten weltweit",
  },
  findUseCases: {
    en: "Find your use cases",
    es: "Encuentre sus casos de uso",
    de: "Finden Sie Ihre Anwendungsfälle",
  },
  galleryTitle: {
    en: "Case studies & projects",
    es: "Nuestra Galería de Proyectos",
    de: "Unsere Projekte Galerie",
  },
  questionCTAButton: {
    en: "If you have any other questions, you can book a free discovery call with us",
    es: "Si tiene alguna otra pregunta, puede programar una llamada de descubrimiento gratuita con nosotros",
    de: "Bei weiteren Fragen können Sie ein kostenloses Erstgespräch mit uns vereinbaren",
  },
  discoveryCallText: {
    en: "Discovery Call",
    es: "Llamada de descubrimiento",
    de: "Beratungsgespräch",
  },
  joinNetwork: {
    en: "Join network",
    es: "Unirse a la red",
    de: "Netzwerk beitreten",
  },
  academyButton: {
    en: "Academy",
    es: "Academia",
    de: "Akademie",
  },
  joinWaitlist: {
    en: "Join the waitlist",
    es: "Únete a la lista de espera",
    de: "Warte­liste beitreten",
  },
  applyNetwork: {
    en: "Apply to join",
    es: "Solicita unirte",
    de: "Bewerben Sie sich",
  },
  network: {
    en: "Network",
    es: "Red",
    de: "Netzwerk",
  },
  searchIndustryLabel: {
    en: "Explore AI Use Cases by Industry",
    es: "Buscar Industria",
    de: "Branche suchen",
  },
  missionText: {
    en: `Our mission is to bridge the gap between AI's vast potential and its
    practical, real-world implementation, and cultivate an ecosystem where AI talent meets ambitious projects.<br><br>Our
    network model sets us apart. By tapping into a global pool of
    specialists and teams, we assemble the ideal squad for each project,
    ensuring access to the most relevant expertise throughout the entire
    journey - from strategy to hands-on
    implementation and management.<br><br> 
    We help our partners navigate the complex AI landscape, transforming ideas into real-world practical solutions.`,
    de: `Unsere Mission ist es, die Lücke zwischen dem großen Potenzial von KI und ihrer praktischen, realen Implementierung zu überbrücken und ein Ökosystem zu pflegen, in dem KI-Talente auf ambitionierte Projekte treffen.<br><br>Unser Netzwerkmodell hebt uns ab. Indem wir auf ein globales Reservoir an Spezialisten und Teams zugreifen, stellen wir für jedes Projekt das ideale Team zusammen, um den Zugang zu den relevantesten Experten während des gesamten Prozesses - von der Strategie bis zur praktischen Umsetzung und Verwaltung - sicherzustellen.<br><br>Wir helfen unseren Partnern, die komplexe KI-Landschaft zu navigieren und Ideen in praktische, realweltliche Lösungen zu verwandeln.`,
    es: `Nuestra misión es tender un puente entre el vasto potencial de la IA y su implementación práctica y real en el mundo, y cultivar un ecosistema donde el talento en IA se encuentre con proyectos ambiciosos.<br><br>Nuestro modelo de red nos distingue. Al acceder a un grupo global de especialistas y equipos, formamos el equipo ideal para cada proyecto, asegurando el acceso a la experiencia más relevante durante todo el proceso - desde la estrategia hasta la implementación práctica y la gestión.<br><br>Ayudamos a nuestros socios a navegar por el complejo panorama de la IA, transformando ideas en soluciones prácticas del mundo real.`,
  },
  missionQuote: {
    en: "Innovation flows like a river, ever-changing. To stay still is to be left behind.",
    de: "Innovation fließt wie ein Fluss, ständig in Bewegung. Stillzustehen bedeutet, zurückzubleiben.",
    es: "La innovación fluye como un río, siempre cambiante. Quedarse quieto es quedarse atrás.",
  },
};
