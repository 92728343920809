import CarouselItem from "./CarouselItem";

import { getCountrySvg } from "../../Utils/countriesSvgs/getCountrySvg";

import "./Carousel.scss";
import { getThemeColor } from "../../tailwindUtils";

export type CarouselDataType = {
  text: string;
  id: string;
}[];

export const Carousel = ({
  className,
  carouselData,
}: {
  className?: string;
  carouselData: CarouselDataType;
}) => {
  const carouselItem = carouselData.map((item, index) => {
    return (
      <CarouselItem
        key={item.id + index}
        text={item.text}
        image={getCountrySvg(item.id, getThemeColor("blue"), "200px", "200px")}
      />
    );
  });

  const mobileCarouselItem = carouselData.map((item, index) => {
    return (
      <CarouselItem
        key={item.id + index}
        text={item.text}
        image={getCountrySvg(item.id, getThemeColor("blue"), "100px", "100px")}
      />
    );
  });

  return (
    <div className={`carousel-wrapper ${className} `}>
      <div className="carousel-track hidden md:flex">{carouselItem}</div>
      <div className="carousel-track hidden md:flex">{carouselItem}</div>
      <div className="carousel-track flex md:hidden">{mobileCarouselItem}</div>
      <div className="carousel-track flex md:hidden">{mobileCarouselItem}</div>
    </div>
  );
};
