import { ReactNode } from "react";

export type PageWrapperType = {
  children: ReactNode;
};

export const PageWrapper = ({ children }: PageWrapperType) => {
  return (
    <div className="px-5 min-h-screen md:px-20 2xl:px-40 overflow-hidden pt-[76px] ">
      {children}
    </div>
  );
};
