export const aiJoinAcademyTranslations = {
  academy: {
    title: {
      en: "Join the Millerio academy waitlist",
      es: "Únete a la lista de espera de la academia Millerio",
      de: "Treten Sie der Warteliste der Millerio-Akademie bei",
    },
    end: {
      en: "All done! We will be in touch soon.",
      es: "¡Todo listo! Nos pondremos en contacto pronto.",
      de: "Fertig! Wir werden uns bald bei Ihnen melden.",
    },
  },
};
