import { cloneElement, isValidElement, ReactNode } from "react";

export type ButtonPropsType = {
  label: string;
  className?: string;
  highlight?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  iconLeft?: boolean;
  action: () => void;
};

export const Button = ({
  label,
  className,
  disabled,
  highlight,
  icon,
  iconLeft,
  action,
}: ButtonPropsType) => {
  const defaultIconProps = {
    height: 18,
    width: 18,
    strokeWidth: 2,
  };
  const iconTransitionClassName = "transition transition-200";
  return (
    <button
      disabled={disabled}
      className={`px-4 py-2 flex group flex-row transition-all duration-500 items-center justify-center gap-1 text-md font-medium shadow-md rounded-lg ${
        disabled
          ? "text-grey"
          : "border-accent-hover active:text-accent active:border-accent"
      } ${
        highlight
          ? "bg-accent/90 text-white hover:bg-accent"
          : "bg-background"
      } ${className}`}
      onClick={action}
    >
      {iconLeft && icon && isValidElement(icon) && (
        <div
          className={`translate-x-1 group-hover:translate-x-2 ${iconTransitionClassName} `}
        >
          {cloneElement(icon, { ...defaultIconProps })}
        </div>
      )}
      {label}
      {!iconLeft && icon && isValidElement(icon) && (
        <div
          className={`translate-x-1 group-hover:translate-x-2 ${iconTransitionClassName}`}
        >
          {cloneElement(icon, { ...defaultIconProps })}
        </div>
      )}
    </button>
  );
};
