import { motion } from "framer-motion";
import { Button, ButtonPropsType } from "../../Components/Button/Button";
import { Carousel, CarouselDataType } from "../../Components/Carousel/Carousel";
import { RotatingText } from "../../Components/RotatingText/RotatingText";
import { useScroll } from "../../Providers/ScrollContextProvider";
import { StepForm } from "../../StepForm/StepForm";
import { StepFormDataType } from "../../StepForm/StepFormTypes";
import { useNavigate } from "react-router";
import { useTranslate } from "../../hooks/useTranslation";
import { ArrowRight } from "lucide-react";
import { InfoText } from "../../Components/InfoText/InfoText";

type HeroPropsTypes = {
  first: string;
  last?: string;
  strings?: string[];
  text?: string;
  infoText?: string;
  className?: string;
  firstButton?: ButtonPropsType;
  secondButton?: ButtonPropsType;
  carouselData?: CarouselDataType;
};

export const Hero = ({
  first,
  last,
  strings,
  text,
  infoText,
  carouselData,
  firstButton,
  secondButton,
  className,
}: HeroPropsTypes) => {
  const { scrollToSection } = useScroll();

  const navigate = useNavigate();
  const translate = useTranslate();

  return (
    <div
      className={`min-h-screen pt-20 md:pt-30 gap-10 relative flex items-center flex-wrap flex-row justify-center max-w-[1200px] mx-auto ${className}`}
    >
      <div className="w-full relative flex flex-col gap-10">
        <motion.div
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.2 }}
          className="heading-1 text-center"
        >
          <div className="flex flex-wrap flex-col items-center w-full justify-center ">
            <h1 className="">{first}</h1>
            {strings && <RotatingText strings={strings} />}
          </div>

          {last}
        </motion.div>
        {text && (
          <motion.div
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.6 }}
          >
            <h3 className="heading-3 text-center mb-5 text-darkGrey/60">
              {text}
            </h3>
          </motion.div>
        )}
        {(firstButton || secondButton) && (
          <motion.div
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 1 }}
            className="flex flex-wrap items-center gap-5 justify-center"
          >
            {firstButton && <Button className="w-fit" {...firstButton} />}
            {secondButton && <Button className="w-fit" {...secondButton} />}
          </motion.div>
        )}
      </div>
      <div>
        {infoText && <InfoText className="mb-10" text={infoText} />}
        {carouselData && (
          <Carousel
            carouselData={carouselData}
            className="full-width-section ml-0"
          />
        )}
      </div>
    </div>
  );
};
