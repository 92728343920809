import { useEffect, useRef } from "react";

import { StepFormDataType, StepFormStepType } from "./StepFormTypes";

import { SafeTextRenderer } from "../Utils/SafeTextRenderer";
import { StepFormAnswer } from "./Components/StepFormAnswer";

import { useStepFormTranslations } from "./StepFormTranslations/useStepFormTranslations";
import { translationLanguageAtom } from "../jotai/state";
import { useAtom, useSetAtom } from "jotai";
import { getSvg } from "../Utils/svgs/getSvg";
import { AnimatePresence, motion } from "framer-motion";
import { currentFormStepAtom, formStateAtom } from "./FormState/FormState";
import { useUserAction } from "./hooks/useUserAction";
import { getThemeColor } from "../tailwindUtils";
import { FadeSeparator } from "../Components/FadeSeparator/FadeSeparator";

export type StepFormPropsTypes = {
  className?: string;
  stepFormData: StepFormDataType;
  overline?: string;
  formId: string;
};

export const StepForm = ({
  className,
  stepFormData,
  overline,
  formId,
}: StepFormPropsTypes) => {
  const [lang] = useAtom(translationLanguageAtom);
  const setFormState = useSetAtom(formStateAtom);
  const [currentFormStep, setCurrentFormStep] = useAtom(currentFormStepAtom);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const translateString = useStepFormTranslations(lang);

  const currentStepData: StepFormStepType =
    stepFormData.steps.find((step: any) => step.id === currentFormStep) ||
    stepFormData.steps[0];
  const stepHeadline = translateString(currentStepData.title);

  const { handleUserAction } = useUserAction(formId, currentStepData);
  console.log(stepFormData)

  useEffect(() => {
    setCurrentFormStep(stepFormData.steps[0].id);
    setFormState(null);
  }, [stepFormData]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [currentFormStep]);

  return (
    <div
      className={`react-typed-container h-full w-full card-highlight bg-background pr-0 overflow-hidden flex flex-col relative ${className}`}
    >
      <div className="p-2 absolute">
        {getSvg(
          "logo",
          getThemeColor("accent"),
          "20px",
          "20px",
          getThemeColor("secondary")
        )}
      </div>
      <div
        className="relative max-h-full min-h-full flex flex-col justify-center items-center w-full p-4 md:p-10 pb-0"
      >
        <AnimatePresence mode="wait">
          {stepHeadline && (
            <motion.div
              className={`flex flex-col h-1/2 gap-2 items-end justify-center md:justify-end`}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              key={`headline-${currentStepData.id}`}
              transition={{ duration: 0.5 }}
            >
              <div className="body-2 step-form-text-container flex items-center justify-center flex-wrap flex-col">
                {overline && (
                  <p className="text-accent text-base">{overline}</p>
                )}
                <SafeTextRenderer
                  className="text-main text-center w-fit flex flex-col px-5 heading-2"
                  text={stepHeadline}
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="wait">
          <motion.div
            key={`step-${currentStepData.id}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 1 }}
            className="overflow-y-auto  h-1/2 overflow-x-hidden mt-5 py-10 items-center justify-center"
          >
            <FadeSeparator className="top-0" />
            <StepFormAnswer
              currentStepData={currentStepData}
              handleUserAction={handleUserAction}
              translate={lang}
            />
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};
