export const aiAcademyTranslations = {
  headline: {
    en: "Learn skills the future needs",
    es: "Aprende las habilidades que el futuro necesita",
    de: "Lerne Fähigkeiten, die die Zukunft braucht",
  },
  heroText: {
    en: "Imagine mastering AI in every aspect of business—understanding its power, applying it to real challenges, managing teams, and driving sales and marketing. Whether you're an engineer, entrepreneur, or executive, our academy gives you the skills to grow and thrive in the AI economy.",
    es: "Imagina dominar la IA en todos los aspectos del negocio: comprender su poder, aplicarla a desafíos reales, gestionar equipos y potenciar ventas y marketing. Ya seas ingeniero, emprendedor o ejecutivo, nuestra academia te brinda las habilidades para crecer y prosperar en la economía de la IA.",
    de: "Stellen Sie sich vor, Sie beherrschen KI in allen Aspekten des Geschäfts – verstehen Sie ihre Macht, wenden Sie sie auf reale Herausforderungen an, leiten Sie Teams und fördern Sie Vertrieb und Marketing. Ob Ingenieur, Unternehmer oder Führungskraft – unsere Akademie vermittelt Ihnen die Fähigkeiten, in der KI-Wirtschaft zu wachsen und zu gedeihen.",
  },
};
