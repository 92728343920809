import { Link, useLocation } from "react-router-dom";
import { getSvg } from "./svgs/getSvg";
import { getThemeColor } from "../tailwindUtils";

export const InteractiveLogo = (onClick?: () => any) => {
  return onClick ? (
    <Link to="/" onClick={() => onClick()} className="flex items-center group">
      {getSvg(
        "logo",
        getThemeColor("accent"),
        "30px",
        "30px",
        getThemeColor("secondary")
      )}
      <h1 className="pl-2 group-hover:text-accent transition-200 text-lg font-medium tracking-wider">
        MILLERIO
      </h1>
    </Link>
  ) : (
    <div className="flex items-center">
      {getSvg(
        "logo",
        getThemeColor("accent"),
        "30px",
        "30px",
        getThemeColor("secondary")
      )}
      <h1 className="pl-2 transition-200 text-lg font-medium tracking-wider">
        MILLERIO
      </h1>
    </div>
  );
};
