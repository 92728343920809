import { NumberCardsList } from "../../modules/NumberCardsList/NumberCardsList";
import { Hero } from "../../modules/Hero/Hero";
import { FAQ } from "../../modules/FAQ/FAQ";
import { TabsSection } from "../../modules/TabsSection/TabsSection";
import { dataFacts } from "../../PageData/dataFacts";
import { aiFaqData } from "../../modules/FAQ/faqData";
import { TextQuote } from "../../modules/TextQuote/TextQuote";

import { PageIndex } from "../../Components/PageIndex/PageIndex";
import { PageWrapper } from "../PageWrapper";
import { SectionWrapper } from "../SectionWrapper";

import { ArticleType, getArticles } from "../../Articles/getArticles";

import { useTranslate } from "../../hooks/useTranslation";

import PhiloImg from "../../Assets/phili.png";
import {
  ArrowRight,
  ArrowRightLeft,
  Binary,
  Bot,
  Brain,
  BrainCircuit,
  Cpu,
  Database,
  FlipHorizontal2,
  Gem,
  Pyramid,
  Route,
  ScanSearch,
  TrendingUpDown,
  Trophy,
  Unplug,
  Users,
  Workflow,
} from "lucide-react";
import { Button } from "../../Components/Button/Button";
import { BubblesSection } from "../../modules/BubblesSection/BubblesSection";
import { useNavigate } from "react-router";
import { CardsShowcase } from "../../modules/CardsShowcase/CardsShowcase";
import { useAtom } from "jotai";
import { translationLanguageAtom } from "../../jotai/state";
import { Quote } from "../../Components/Quote/Quote";
import { carouselCountriesData } from "../../Components/Carousel/carouselConfig";

export const AIPage = () => {
  const translate = useTranslate();
  const articles: any = getArticles("ai");
  const [lang] = useAtom(translationLanguageAtom);

  const navigate = useNavigate();

  const articlesIdToShowcase = [2, 4, 6];

  const articleShowcase: ArticleType[] = articles.filter(
    (article: ArticleType) => articlesIdToShowcase.includes(article.metadata.id)
  );

  // const sections = [
  //   { id: "hero", name: "Hero" },
  //   { id: "services", name: "AI Solutions" },
  //   { id: "dataFacts", name: "Data Facts" },
  //   { id: "gallery", name: "Projects Gallery" },
  //   { id: "callToAction", name: "Get In Touch" },
  //   { id: "experience", name: "Use Cases" },
  //   { id: "mission", name: "Our Mission" },
  //   { id: "faq", name: "FAQ" },
  // ];

  return (
    <PageWrapper>
      {/* <PageIndex sections={sections} /> */}
      <div id="hero">
        <Hero
          firstButton={{
            label: translate("ai.discoveryCallText"),
            highlight: true,
            icon: <ArrowRight />,
            action: () => navigate("/form/discovery"),
          }}
          secondButton={{
            label: translate("ai.joinNetwork"),
            icon: <ArrowRight />,
            action: () => navigate("/form/talent"),
          }}
          first={translate("ai.hero.first")}
          last={translate("ai.hero.last")}
          carouselData={carouselCountriesData}
          infoText={translate("ai.trustedPartner")}
          strings={[
            translate("ai.hero.strings.a"),
            translate("ai.hero.strings.b"),
            translate("ai.hero.strings.c"),
            translate("ai.hero.strings.d"),
          ]}
          text={translate("ai.hero.text")}
        />
      </div>
      <SectionWrapper
        transition
        id="dataFacts"
        headline={translate("ai.dataFacts.headline")}
      >
        <NumberCardsList numberCardsList={dataFacts} />
      </SectionWrapper>
      <SectionWrapper
        id="consultancy"
        transition
        dark
        fullWidth
        description={translate("ai.services.solutions.a.text")}
        headline={translate("ai.services.solutions.a.headline")}
      >
        <BubblesSection
          bubbles={[
            {
              text: translate("ai.services.solutions.a.listOfItems.a.text"),
              icon: <Gem />,
            },
            {
              text: translate("ai.services.solutions.a.listOfItems.b.text"),
              icon: <ScanSearch />,
            },
            {
              text: translate("ai.services.solutions.a.listOfItems.c.text"),
              icon: <Route />,
            },
            {
              text: translate("ai.services.solutions.a.listOfItems.d.text"),
              icon: <Trophy />,
            },
          ]}
          button={{
            label: translate("ai.discoveryCallText"),
            link: "/form/discovery",
          }}
        />
      </SectionWrapper>
      <SectionWrapper
        id="development"
        transition
        fullWidth
        description={translate("ai.services.development.description")}
        headline={translate("ai.services.development.headline")}
      >
        <div className="flex flex-col items-center gap-10">
          <TabsSection
            sections={[
              {
                headline: "ai.services.development.a.headline",
                text: "ai.services.development.a.text",
                content: (
                  <BubblesSection
                    bubbles={[
                      {
                        text: translate(
                          "ai.services.development.a.listOfItems.a.text"
                        ),
                        icon: <Workflow />,
                      },
                      {
                        text: translate(
                          "ai.services.development.a.listOfItems.b.text"
                        ),
                        icon: <Brain />,
                      },
                      {
                        text: translate(
                          "ai.services.development.a.listOfItems.c.text"
                        ),
                        icon: <Unplug />,
                      },
                    ]}
                  />
                ),
              },
              {
                headline: "ai.services.development.b.headline",
                text: "ai.services.development.b.text",
                content: (
                  <BubblesSection
                    bubbles={[
                      {
                        text: translate(
                          "ai.services.development.b.listOfItems.a.text"
                        ),
                        icon: <FlipHorizontal2 />,
                      },
                      {
                        text: translate(
                          "ai.services.development.b.listOfItems.b.text"
                        ),
                        icon: <Bot />,
                      },
                      {
                        text: translate(
                          "ai.services.development.b.listOfItems.c.text"
                        ),
                        icon: <ArrowRightLeft />,
                      },
                    ]}
                  />
                ),
              },
              {
                headline: "ai.services.development.c.headline",
                text: "ai.services.development.c.text",
                content: (
                  <BubblesSection
                    bubbles={[
                      {
                        text: translate(
                          "ai.services.development.c.listOfItems.a.text"
                        ),
                        icon: <Cpu />,
                      },
                      {
                        text: translate(
                          "ai.services.development.c.listOfItems.b.text"
                        ),
                        icon: <Database />,
                      },
                      {
                        text: translate(
                          "ai.services.development.c.listOfItems.c.text"
                        ),
                        icon: <BrainCircuit />,
                      },
                    ]}
                  />
                ),
              },
            ]}
          />
          <Button
            highlight
            action={() => navigate("/form/discovery")}
            label={translate("ai.discoveryCallText")}
          />
        </div>
      </SectionWrapper>
      <SectionWrapper
        id="networking"
        transition
        dark
        fullWidth
        description={translate("ai.services.solutions.c.text")}
        headline={translate("ai.services.solutions.c.headline")}
      >
        <BubblesSection
          bubbles={[
            {
              text: translate("ai.services.solutions.c.listOfItems.a.text"),
              icon: <Pyramid />,
            },
            {
              text: translate("ai.services.solutions.c.listOfItems.b.text"),
              icon: <Users />,
            },
            {
              text: translate("ai.services.solutions.c.listOfItems.c.text"),
              icon: <Binary />,
            },
            {
              text: translate("ai.services.solutions.c.listOfItems.d.text"),
              icon: <TrendingUpDown />,
            },
          ]}
          button={{
            label: translate("ai.applyNetwork"),
            link: "/form/talent",
          }}
        />
      </SectionWrapper>
      <SectionWrapper
        id="gallery"
        transition
        headline={translate("ai.galleryTitle")}
      >
        {/* <ArticlesSlider articles={articles} /> */}
        <div className="flex flex-col items-center justify-center gap-20">
          <CardsShowcase
            cards={articleShowcase.map((card) => ({
              image: card.metadata.image,
              title: card.title[lang],
              link: `/article/${card.metadata.id.toString()}`,
              text: card.metadata.description[lang],
              tag: card.metadata.tag[lang],
            }))}
          />
          <Button
            label={"Read more"}
            action={() => navigate("/articles")}
            icon={<ArrowRight />}
          />
        </div>
      </SectionWrapper>
      <SectionWrapper id="callToAction" transition>
        <Quote text={translate("ai.callToAction.headline")} />
      </SectionWrapper>
      <SectionWrapper transition id="mission" headline="The Millerio Mission">
        <TextQuote
          bgImage={PhiloImg}
          text={translate("ai.missionText")}
          quote={translate("ai.missionQuote")}
          author={"Heraclitus"}
        />
      </SectionWrapper>
      <SectionWrapper
        transition
        id="faq"
        headline={translate(aiFaqData.headline)}
      >
        <FAQ data={aiFaqData} />
      </SectionWrapper>
    </PageWrapper>
  );
};
