import { StepFormDataType, StepFormStepType } from "../../StepFormTypes";
import {
  getStepFormButtonFromArray,
  getStepFormButton,
  getStepFormInputField,
} from "../formDataElementsUtils";

const talentExperience = [
  "ai.talent.experience.noExperienceLabel",
  "ai.talent.experience.underOne",
  "ai.talent.experience.oneThree",
  "ai.talent.experience.threeFive",
  "ai.talent.experience.overFive",
];

const aiSubfields = [
  "ai.subFields.strategyConsultancy",
  "ai.subFields.solutions",
  "ai.subFields.machineLearning",
  "ai.subFields.neuralNetworks",
  "ai.subFields.computerVision",
  "ai.subFields.deepLearning",
  "ai.subFields.data",
  "ai.talent.fields.other",
  "ai.talent.fields.multiple",
];

const availability = [
  "ai.startingTimeframes.now",
  "ai.startingTimeframes.oneMonth",
  "ai.startingTimeframes.oneThreeMonth",
  "ai.startingTimeframes.threeSix",
  "ai.startingTimeframes.sixMore",
];

const teamSize = [
  "ai.talent.teamSize.a",
  "ai.talent.teamSize.b",
  "ai.talent.teamSize.c",
  "ai.talent.teamSize.d",
];

export const stepFormDataTalent: StepFormDataType = {
  steps: [
    {
      title: "ai.talent.start",
      id: "talent-1",
      answer: {
        type: "button_options",
        answerConfig: {
          options: [
            getStepFormButton(
              "ai.talent.asExpertLabel",
              "talent-professional-1",
              undefined,
              true
            ),
            getStepFormButton(
              "ai.talent.asAgencyLabel",
              "talent-team-1",
              undefined,
              true
            ),
          ],
        },
      },
    },
    {
      id: "talent-team-1",
      title: "ai.talent.experience.title",
      answer: {
        type: "button_options",
        answerConfig: {
          options: [
            ...getStepFormButtonFromArray(talentExperience, "talent-team-2"),
          ],
        },
      },
    },
    {
      id: "talent-team-2",
      title: "ai.talent.teamSize.title",
      answer: {
        type: "button_options",
        answerConfig: {
          options: [...getStepFormButtonFromArray(teamSize, "talent-2")],
        },
      },
    },
    {
      id: "talent-professional-1",
      title: "ai.talent.experience.title",
      answer: {
        type: "button_options",
        answerConfig: {
          options: [
            ...getStepFormButtonFromArray(talentExperience, "talent-2"),
          ],
        },
      },
    },
    {
      id: "talent-2",
      title: "ai.talent.fields.title",
      answer: {
        type: "button_options",
        answerConfig: {
          options: [...getStepFormButtonFromArray(aiSubfields, "talent-3")],
        },
      },
    },
    {
      id: "talent-3",
      title: "ai.talent.availability.title",
      answer: {
        type: "button_options",
        answerConfig: {
          options: [...getStepFormButtonFromArray(availability, "talent-4")],
        },
      },
    },
    {
      id: "talent-4",
      title: "ai.talent.contactInfo.title",
      answer: {
        type: "input_fields",
        answerConfig: {
          submitForm: true,
          fields: [
            getStepFormInputField("ai.talent.contactInfo.name", "name"),
            getStepFormInputField("ai.talent.contactInfo.email", "email"),
            getStepFormInputField("ai.talent.contactInfo.websiteLinkedin"),
          ],
          nextButtonLabel: "ai.submit",
          nextStepId: "talent-5",
        },
      },
    },
    {
      id: "talent-5",
      title: "ai.bookCall.end",
      answer: {
        type: "info",
        answerConfig: {},
      },
    },
  ],
};
