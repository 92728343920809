import { aiBusinessSectorsTranslations } from "./aiBusinessSectorsTranslations";
import { aiCompanyPositionsTranslations } from "./aiCompanyPositionsTranslations";
import { aiHowWorksTranslations } from "./aiHowWorksTranslations";
import { aiStartingTimeframesTranslations } from "./aiStartingTimeframesTranslations";
import { aiSubFieldsTranslations } from "./aiSubFieldsTranslations";
import { aiTalentTranslations } from "./aiTalentTranslations";
import { aiBookCallTranslations } from "./bookCallTranslations";
import { aiJoinAcademyTranslations } from "./aiJoinAcademyTranslations";

export const aiTranslations = {
  howWorks: aiHowWorksTranslations,
  businessSector: aiBusinessSectorsTranslations,
  talent: aiTalentTranslations,
  startingTimeframes: aiStartingTimeframesTranslations,
  subFields: aiSubFieldsTranslations,
  companyPositions: aiCompanyPositionsTranslations,
  submit: {
    en: "Submit",
    de: "Einreichen",
    es: "Enviar"
  },
  ...aiBookCallTranslations,
  ...aiJoinAcademyTranslations
};
