import React from "react";
import { PageWrapper } from "../PageWrapper";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { Hero } from "../../modules/Hero/Hero";
import { useTranslate } from "../../hooks/useTranslation";
import { ArrowRight } from "lucide-react";

export const AcademyPage: React.FC = () => {
  const navigate = useNavigate();
  const translate = useTranslate();

  return (
    <PageWrapper>
      <Hero
        text={translate("ai.academy.heroText")}
        firstButton={{
          label: translate("ai.joinWaitlist"),
          highlight: true,
          icon: <ArrowRight />,
          className: "w-fit",
          action: () => navigate("/form/academy"),
        }}
        first={translate("ai.academy.headline")}
      />
    </PageWrapper>
  );
};
