import React from "react";
import logo from "../../Assets/logo.png";
import { FadeSeparator } from "../../Components/FadeSeparator/FadeSeparator";
import { InteractiveLogo } from "../../Utils/InteractiveLogo";
import { headerLinks } from "../Header/Header";
import { Link } from "react-router-dom";
import { useTranslate } from "../../hooks/useTranslation";
import { LanguageSelector } from "../../Components/LanguageSelector/LanguageSelector";

export const Footer = () => {
  const translate = useTranslate();

  return (
    <div className="p-5 md:p-40 mt-20 md:mt-40 bg-background text-main flex-col md:flex-col flex items-center justify-between relative gap-10">
      <FadeSeparator className="top-0 -translate-x-1/2 left-1/2" />
      <div className="flex items-center w-fit flex-col gap-10">
        {InteractiveLogo()}
        <LanguageSelector />
      </div>
      <div className="flex flex-row  gap-5">
        {headerLinks.map((link, index) => (
          <React.Fragment key={index}>
            <Link
              to={link.link}
              className="body-2 hover:text-accent transition-colors text-center"
            >
              {translate(link.label)}
            </Link>
          </React.Fragment>
        ))}
      </div>
      <div>
      <p className="body-3" >MillerioTech © {(new Date().getFullYear())}</p>
      </div>
    </div>
  );
};
